import { getFirestore, collection, addDoc, query, where, getDocs, orderBy, limit, doc, getDoc, deleteDoc, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

// Initialize Firestore from the Firebase app
const db = getFirestore();

// Function to add a comment to an article
export const addComment = async (articleId, commentText) => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    
    if (!user) {
      throw new Error('User must be logged in to comment');
    }
    
    // Create comment data
    const commentData = {
      articleId,
      userId: user.uid,
      authorName: user.displayName || 'Anonymous',
      authorImage: user.photoURL || null,
      content: commentText,
      createdAt: new Date(),
      likes: 0,
      likedBy: []
    };
    
    // Add comment to Firestore
    const docRef = await addDoc(collection(db, 'comments'), commentData);
    
    // Update comment count on the article
    const articleRef = doc(db, 'articles', articleId);
    const articleDoc = await getDoc(articleRef);
    
    if (articleDoc.exists()) {
      const articleData = articleDoc.data();
      const commentCount = articleData.commentCount || 0;
      
      await updateDoc(articleRef, {
        commentCount: commentCount + 1
      });
    }
    
    return { 
      id: docRef.id, 
      ...commentData 
    };
  } catch (error) {
    console.error('Error adding comment:', error);
    throw error;
  }
};

// Function to get comments for an article
export const getComments = async (articleId, limitCount = 50) => {
  try {
    // Query comments for the article
    const commentsRef = collection(db, 'comments');
    const q = query(
      commentsRef, 
      where('articleId', '==', articleId),
      orderBy('createdAt', 'desc'),
      limit(limitCount)
    );
    
    const querySnapshot = await getDocs(q);
    const comments = [];
    
    querySnapshot.forEach((doc) => {
      comments.push({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt.toDate() // Convert Firestore timestamp to JS Date
      });
    });
    
    // Sort comments by date (newest first)
    return comments.sort((a, b) => b.createdAt - a.createdAt);
  } catch (error) {
    console.error('Error getting comments:', error);
    throw error;
  }
};

// Function to delete a comment
export const deleteComment = async (commentId) => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    
    if (!user) {
      throw new Error('User must be logged in to delete a comment');
    }
    
    // Get the comment
    const commentRef = doc(db, 'comments', commentId);
    const commentDoc = await getDoc(commentRef);
    
    if (!commentDoc.exists()) {
      throw new Error('Comment not found');
    }
    
    const commentData = commentDoc.data();
    
    // Check if user is the author of the comment
    if (commentData.userId !== user.uid) {
      throw new Error('You can only delete your own comments');
    }
    
    // Delete the comment
    await deleteDoc(commentRef);
    
    // Update comment count on the article
    const articleRef = doc(db, 'articles', commentData.articleId);
    const articleDoc = await getDoc(articleRef);
    
    if (articleDoc.exists()) {
      const articleData = articleDoc.data();
      const commentCount = articleData.commentCount || 0;
      
      if (commentCount > 0) {
        await updateDoc(articleRef, {
          commentCount: commentCount - 1
        });
      }
    }
    
    return { success: true, message: 'Comment deleted successfully' };
  } catch (error) {
    console.error('Error deleting comment:', error);
    throw error;
  }
};

// Function to like a comment
export const likeComment = async (commentId) => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    
    if (!user) {
      throw new Error('User must be logged in to like a comment');
    }
    
    // Get the comment
    const commentRef = doc(db, 'comments', commentId);
    const commentDoc = await getDoc(commentRef);
    
    if (!commentDoc.exists()) {
      throw new Error('Comment not found');
    }
    
    const commentData = commentDoc.data();
    const likedBy = commentData.likedBy || [];
    
    // Check if user already liked the comment
    if (likedBy.includes(user.uid)) {
      // User already liked, so unlike
      await updateDoc(commentRef, {
        likes: commentData.likes - 1,
        likedBy: likedBy.filter(id => id !== user.uid)
      });
      
      return { success: true, liked: false, likes: commentData.likes - 1 };
    } else {
      // User hasn't liked, so add like
      await updateDoc(commentRef, {
        likes: commentData.likes + 1,
        likedBy: [...likedBy, user.uid]
      });
      
      return { success: true, liked: true, likes: commentData.likes + 1 };
    }
  } catch (error) {
    console.error('Error liking comment:', error);
    throw error;
  }
};

// Function to check if user has liked a comment
export const hasUserLikedComment = async (commentId) => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    
    if (!user) {
      return false;
    }
    
    // Get the comment
    const commentRef = doc(db, 'comments', commentId);
    const commentDoc = await getDoc(commentRef);
    
    if (!commentDoc.exists()) {
      return false;
    }
    
    const commentData = commentDoc.data();
    const likedBy = commentData.likedBy || [];
    
    return likedBy.includes(user.uid);
  } catch (error) {
    console.error('Error checking if user liked comment:', error);
    return false;
  }
};

// Function to get user's comments
export const getUserComments = async (limitCount = 20) => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    
    if (!user) {
      throw new Error('User must be logged in to view their comments');
    }
    
    // Query comments by the user
    const commentsRef = collection(db, 'comments');
    const q = query(
      commentsRef, 
      where('userId', '==', user.uid),
      orderBy('createdAt', 'desc'),
      limit(limitCount)
    );
    
    const querySnapshot = await getDocs(q);
    const comments = [];
    
    querySnapshot.forEach((doc) => {
      comments.push({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt.toDate() // Convert Firestore timestamp to JS Date
      });
    });
    
    return comments;
  } catch (error) {
    console.error('Error getting user comments:', error);
    throw error;
  }
};

export default {
  addComment,
  getComments,
  deleteComment,
  likeComment,
  hasUserLikedComment,
  getUserComments
};

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getBookmarkedArticles, removeBookmark } from '../services/firebase/bookmarks';
import '../styles/global.css';
import '../styles/bookmarks.css';

const BookmarksPage = () => {
  const [bookmarked, setBookmarked] = useState([]);
  const [loading, setLoading] = useState(true);

  const bgStyle = {
    backgroundColor: '#f9f7f2',
    minHeight: '100vh'
  };

  useEffect(() => {
    const loadBookmarks = async () => {
      try {
        const data = await getBookmarkedArticles();
        setBookmarked(data);
      } catch (err) {
        console.error('Error loading bookmarks:', err);
      } finally {
        setLoading(false);
      }
    };
    loadBookmarks();
  }, []);

  const handleRemove = async (articleId) => {
    try {
      await removeBookmark(articleId);
      setBookmarked(prev => prev.filter(a => a.id !== articleId));
    } catch (err) {
      console.error('Error removing bookmark:', err);
    }
  };

  if (loading) return <div className="container">Loading bookmarks...</div>;

  return (
    <div style={bgStyle}>
      <div className="container">
        <main style={{ padding: '2rem 0' }}>
          <h2 style={{ textAlign: 'center', fontSize: '2rem', marginBottom: '2rem' }}>Bookmarked Articles</h2>

          {bookmarked.length === 0 ? (
            <p style={{ textAlign: 'center', fontStyle: 'italic' }}>You haven't saved any articles yet.</p>
          ) : (
            <ul style={{ listStyle: 'none', padding: 0 }}>
              {bookmarked.map(article => (
                <li key={article.id} style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderBottom: '1px solid #ccc',
                  padding: '0.75rem 0'
                }}>
                  <Link to={`/article/${article.id}`} style={{
                    fontWeight: 'bold',
                    textDecoration: 'none',
                    color: '#000'
                  }}>
                    {article.title}
                  </Link>
                  <button
                    onClick={() => handleRemove(article.id)}
                    style={{
                      background: 'none',
                      border: 'none',
                      color: '#a00',
                      cursor: 'pointer',
                      fontSize: '0.9rem'
                    }}
                  >
                    ✕ Remove
                  </button>
                </li>
              ))}
            </ul>
          )}
        </main>
      </div>
    </div>
  );
};

export default BookmarksPage;

import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const ProtectedRoute = ({ children }) => {
  const [userChecked, setUserChecked] = useState(false);
  const [isAllowed, setIsAllowed] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && user.emailVerified) {
        setIsAllowed(true);
      } else {
        setIsAllowed(false);
      }
      setUserChecked(true);
    });

    return () => unsubscribe();
  }, []);

  if (!userChecked) return <div className="container">Checking authentication...</div>;

  if (!isAllowed) {
    return (
      <div className="container" style={{ padding: '3rem', textAlign: 'center' }}>
        <h2 style={{ color: '#900' }}>🔒 Access Denied</h2>
        <p>You must be <strong>logged in</strong> and have <strong>verified your email</strong> to access this page.</p>
      </div>
    );
  }

  return children;
};

export default ProtectedRoute;

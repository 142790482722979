import React from 'react';
import '../styles/footer.css';
import '../styles/global.css'; // تأكد أن التنسيق الأساسي متاح

const Footer = () => {
  return (
    <footer className="footer" style={{ borderTop: '2px solid #000', padding: '20px 0', textAlign: 'center', marginTop: '30px', backgroundColor: 'rgba(249, 247, 242, 0.9)' }}>
      <p>&copy; {new Date().getFullYear()} MyColu. All rights reserved.</p>
    </footer>
  );
};

export default Footer;

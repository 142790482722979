import { doc, getDoc, collection, getDocs, query, orderBy, limit as limitFn, where, setDoc, serverTimestamp } from "firebase/firestore";
import { db } from "./config";
import { storage } from './config';
import { ref, uploadString, getDownloadURL } from 'firebase/storage';

// جلب مقال واحد
export const getArticleById = async (id) => {
  try {
    const docRef = doc(db, "articles", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const articleData = { id: docSnap.id, ...docSnap.data() };
      
      // إذا كان المحتوى في Storage، نقوم بجلبه
      if (articleData.contentUrl) {
        try {
          const response = await fetch(articleData.contentUrl);
          const fullContent = await response.text();
          articleData.content = fullContent;
        } catch (error) {
          console.error("Error fetching full content:", error);
          // في حالة فشل جلب المحتوى الكامل، نستخدم المعاينة المخزنة في Firestore
        }
      }
      
      return articleData;
    } else {
      throw new Error("Article not found");
    }
  } catch (error) {
    console.error("Error fetching article:", error);
    throw error;
  }
};

// جلب قائمة مقالات للصفحة الرئيسية
export const getArticles = async (limit = 10, offset = 0) => {
  try {
    const articlesRef = collection(db, "articles");
    
    // إنشاء استعلام مع ترتيب وتحديد عدد النتائج
    const q = query(
      articlesRef,
      orderBy("createdAt", "desc"),
      limitFn(limit)
    );
    
    const querySnapshot = await getDocs(q);
    const articles = [];
    
    // جلب المقالات وصورها
    for (const doc of querySnapshot.docs) {
      const data = doc.data();
      let createdAtDate;

      if (data.createdAt) {
        if (data.createdAt.toDate) {
          createdAtDate = data.createdAt.toDate();
        } else if (data.createdAt instanceof Date) {
          createdAtDate = data.createdAt;
        } else if (typeof data.createdAt === 'string') {
          createdAtDate = new Date(data.createdAt);
        } else {
          createdAtDate = new Date(data.createdAt);
        }
      } else {
        createdAtDate = new Date();
      }

      // إذا كان المحتوى في Storage، نقوم بجلبه
      let fullContent = data.content;
      if (data.contentUrl) {
        try {
          const response = await fetch(data.contentUrl);
          fullContent = await response.text();
        } catch (error) {
          console.error("Error fetching full content:", error);
          // في حالة فشل جلب المحتوى الكامل، نستخدم المعاينة المخزنة في Firestore
        }
      }

      // استخراج أول صورة من المحتوى الكامل
      let image = data.image;
      if (!image && fullContent) {
        const imgMatch = fullContent.match(/<img[^>]+src="([^">]+)"/);
        if (imgMatch) {
          image = imgMatch[1];
        }
      }

      articles.push({
        id: doc.id,
        ...data,
        content: fullContent || data.content,
        image: image,
        createdAt: createdAtDate
      });
    }
    
    return articles;
  } catch (error) {
    console.error("Error fetching articles:", error);
    throw error;
  }
};

// وظيفة لتخزين المحتوى في Storage
const storeContent = async (content, articleId) => {
  const contentRef = ref(storage, `articles/${articleId}/content.txt`);
  await uploadString(contentRef, content);
  return await getDownloadURL(contentRef);
};

// تعديل وظيفة إضافة مقال جديد
export const addArticle = async (articleData) => {
  try {
    // نسخة من بيانات المقال للتعديل
    let processedArticleData = { ...articleData };
    delete processedArticleData.content; // حذف المحتوى مؤقتاً

    // إنشاء معرف فريد للمقال
    const articleRef = doc(collection(db, 'articles'));
    const articleId = articleRef.id;

    // تخزين المحتوى في Storage أولاً
    const contentRef = ref(storage, `articles/${articleId}/content.txt`);
    await uploadString(contentRef, articleData.content);
    const contentUrl = await getDownloadURL(contentRef);

    // إضافة معاينة قصيرة ورابط المحتوى
    processedArticleData = {
      ...processedArticleData,
      id: articleId,
      contentUrl,
      content: articleData.content.substring(0, 500), // معاينة قصيرة جداً
      fullContentInStorage: true,
      createdAt: serverTimestamp()
    };

    // حفظ البيانات في Firestore
    await setDoc(articleRef, processedArticleData);

    return articleId;
  } catch (error) {
    console.error('Error adding article:', error);
    throw error;
  }
};

// تعديل وظيفة قراءة المقال
export const getArticle = async (articleId) => {
  try {
    const articleDoc = await getDoc(doc(db, 'articles', articleId));
    if (!articleDoc.exists()) {
      throw new Error('Article not found');
    }

    const articleData = articleDoc.data();

    // إذا كان المحتوى في Storage، نقوم بجلبه
    if (articleData.contentUrl) {
      const contentRef = ref(storage, `articles/${articleId}/content.txt`);
      const content = await getDownloadURL(contentRef);
      const response = await fetch(content);
      articleData.content = await response.text();
    }

    return articleData;
  } catch (error) {
    console.error('Error getting article:', error);
    throw error;
  }
};

// Firebase configuration
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Firebase configuration from project requirements
const firebaseConfig = {
  apiKey: "AIzaSyA4sY6Q40p3O20THHj4KQyFfIEdtbDpaVY",
  authDomain: "mycolu-f1632.firebaseapp.com",
  projectId: "mycolu-f1632",
  storageBucket: "mycolu-f1632.firebasestorage.app",
  messagingSenderId: "394212371156",
  appId: "1:394212371156:web:412f1436272a0c91ffe941"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };
export default app;

import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import '../styles/global.css';
import '../styles/articleTemplates.css';
import { getArticleById } from '../services/firebase/articles';
import {
  getComments,
  addComment,
  deleteComment,
  likeComment
} from '../services/firebase/comments';

const ArticlePage = () => {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [comments, setComments] = useState([]);
  const [commentText, setCommentText] = useState('');
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [error, setError] = useState(null);

  const bgStyle = {
    backgroundColor: '#f9f7f2',
    minHeight: '100vh'
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const auth = getAuth();
        setUser(auth.currentUser);

        const art = await getArticleById(id);
        if (!art) {
          setError('المقال غير موجود');
          return;
        }
        setArticle(art);
        setIsBookmarked(art.isBookmarked || false);

        const loadedComments = await getComments(id);
        setComments(loadedComments);
      } catch (err) {
        console.error(err);
        setError('حدث خطأ أثناء تحميل المقال');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (!commentText.trim() || !user) return;

    try {
      const newComment = await addComment(id, commentText);
      setComments(prev => [...prev, newComment]);
      setCommentText('');
    } catch (err) {
      console.error('Error adding comment:', err);
    }
  };

  const handleDelete = async (commentId) => {
    try {
      await deleteComment(commentId);
      setComments(prev => prev.filter(c => c.id !== commentId));
    } catch (err) {
      console.error('Error deleting comment:', err);
    }
  };

  const handleLike = async (commentId) => {
    try {
      const result = await likeComment(commentId);
      setComments(prev =>
        prev.map(c =>
          c.id === commentId
            ? { ...c, likes: result.likes, likedBy: result.liked ? [...(c.likedBy || []), user.uid] : c.likedBy.filter(id => id !== user.uid) }
            : c
        )
      );
    } catch (err) {
      console.error('Error liking comment:', err);
    }
  };

  if (loading) return (
    <div style={bgStyle}>
      <div className="container">
        <div className="loading-spinner">جاري التحميل...</div>
      </div>
    </div>
  );

  if (error) return (
    <div style={bgStyle}>
      <div className="container">
        <div className="error-message">{error}</div>
      </div>
    </div>
  );

  const getTemplateClass = () => {
    if (!article?.template?.name) return 'standard-article';
    return `template-${article.template.name.toLowerCase().replace(/\s/g, '-')}`;
  };

  return (
    <div style={bgStyle}>
      <div className="container">
        <article className={`article-page ${getTemplateClass()}`}>
          {/* عنوان العمود */}
          <div className="column-title">
            {article.columnTitle || 'MyColu'}
          </div>

          {/* رأس المقال */}
          <header className="article-header">
            <h1 className="article-title">{article.title}</h1>
            
            <div className="article-meta">
              <div className="author-info">
                <img 
                  src={article.authorImage || '/images/default-user.png'} 
                  alt={article.author} 
                  className="author-image" 
                />
                <div className="author-details">
                  <span className="author-name">BY {article.author}</span>
                  <span className="article-date">
                    {new Date(article.createdAt || Date.now()).toLocaleDateString('en-US', {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    })}
                  </span>
                </div>
              </div>
            </div>

            {/* صورة المقال الرئيسية */}
            {article.image && (
              <div className="article-featured-image">
                <img src={article.image} alt={article.title} />
              </div>
            )}
          </header>

          {/* محتوى المقال */}
          <div className="article-content">
            <div
              className="article-body"
              dangerouslySetInnerHTML={{ __html: article.content }}
            />
          </div>

          {/* أزرار التفاعل */}
          <div className="article-actions">
            <button className="action-button comments-button">
              <span className="comments-count">{comments.length}</span> Comments
            </button>
            <button className="action-button share-button">
              Share
            </button>
            {user && (
              <button 
                className={`action-button bookmark-button ${isBookmarked ? 'bookmarked' : ''}`}
              >
                {isBookmarked ? 'Bookmarked' : 'Bookmark'}
              </button>
            )}
          </div>

          {/* قسم التعليقات */}
          <section className="comments-section">
            <h3 className="comments-title">Comments ({comments.length})</h3>

            {user ? (
              <form className="comment-form" onSubmit={handleCommentSubmit}>
                <div className="comment-form-header">
                  <img 
                    src={user.photoURL || '/images/default-user.png'} 
                    alt={user.displayName || 'User'} 
                    className="author-image" 
                  />
                  <span className="comment-form-name">{user.displayName || 'User'}</span>
                </div>
                <textarea
                  className="comment-input"
                  placeholder="Write a comment..."
                  value={commentText}
                  onChange={(e) => setCommentText(e.target.value)}
                  rows="4"
                ></textarea>
                <button type="submit" className="comment-button">Submit Comment</button>
              </form>
            ) : (
              <div className="login-to-comment">
                <p>Please log in to comment on this article.</p>
                <button className="login-button">Login</button>
              </div>
            )}

            <div className="comments-list">
              {comments.length > 0 ? (
                comments.map((comment) => (
                  <div key={comment.id} className="comment">
                    <div className="comment-header">
                      <img 
                        src={comment.authorImage || '/images/default-user.png'} 
                        alt={comment.authorName} 
                        className="author-image" 
                      />
                      <div className="comment-header-info">
                        <span className="author-name">{comment.authorName}</span>
                        <span className="comment-date">
                          {new Date(comment.createdAt).toLocaleDateString()}
                        </span>
                      </div>
                    </div>
                    <div className="comment-content">{comment.content}</div>
                    <div className="comment-actions">
                      <span className="likes-count">
                        {comment.likes || 0} {comment.likes === 1 ? 'like' : 'likes'}
                      </span>
                      {user && (
                        <>
                          <button
                            onClick={() => handleLike(comment.id)}
                            className={`like-button ${comment.likedBy?.includes(user.uid) ? 'liked' : ''}`}
                          >
                            {comment.likedBy?.includes(user.uid) ? 'Unlike' : 'Like'}
                          </button>
                          {comment.userId === user.uid && (
                            <button
                              onClick={() => handleDelete(comment.id)}
                              className="delete-button"
                            >
                              Delete
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div className="no-comments">
                  <p>No comments yet. Be the first to comment!</p>
                </div>
              )}
            </div>
          </section>
        </article>
      </div>
    </div>
  );
};

export default ArticlePage;

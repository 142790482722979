import { getFirestore, collection, addDoc, query, where, getDocs, GeoPoint, orderBy, limit, doc, setDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { storage } from './config';
import { ref, uploadString, getDownloadURL } from 'firebase/storage';

// Initialize Firestore from the Firebase app
const db = getFirestore();

// Function to calculate distance between two points using the Haversine formula
const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the Earth in km
  const dLat = (lat2 - lat1) * Math.PI / 180;
  const dLon = (lon2 - lon1) * Math.PI / 180;
  const a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
    Math.sin(dLon/2) * Math.sin(dLon/2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  const distance = R * c; // Distance in km
  return distance;
};

// Function to get user's current location
export const getUserLocation = () => {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      reject(new Error('Geolocation is not supported by your browser'));
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const location = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          };
          resolve(location);
        },
        (error) => {
          reject(error);
        }
      );
    }
  });
};

// Function to get location name from coordinates using reverse geocoding
export const getLocationName = async (latitude, longitude) => {
  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=10`
    );
    const data = await response.json();
    
    // Extract city or town name
    let locationName = '';
    if (data.address) {
      locationName = data.address.city || 
                    data.address.town || 
                    data.address.village || 
                    data.address.county ||
                    data.address.state;
    }
    
    return locationName || 'Unknown Location';
  } catch (error) {
    console.error('Error getting location name:', error);
    return 'Unknown Location';
  }
};

// Function to save article with location data
export const saveArticleWithLocation = async (articleData) => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    
    if (!user) {
      throw new Error('User must be logged in to publish an article');
    }
    
    // Get user's current location
    const location = await getUserLocation();
    const locationName = await getLocationName(location.latitude, location.longitude);
    
    // Create a GeoPoint for Firestore
    const geoPoint = new GeoPoint(location.latitude, location.longitude);
    
    // نسخة من بيانات المقال للتعديل
    let processedArticleData = { ...articleData };
    delete processedArticleData.content; // حذف المحتوى مؤقتاً

    // إنشاء معرف فريد للمقال
    const articleRef = collection(db, 'articles');
    const tempDocRef = doc(articleRef);
    const articleId = tempDocRef.id;

    // تخزين المحتوى في Storage أولاً
    const contentRef = ref(storage, `articles/${articleId}/content.txt`);
    await uploadString(contentRef, articleData.content);
    const contentUrl = await getDownloadURL(contentRef);
    
    // Prepare article data with location and author info
    const articleWithLocation = {
      ...processedArticleData,
      id: articleId,
      contentUrl,
      content: articleData.content.substring(0, 500), // معاينة قصيرة جداً
      fullContentInStorage: true,
      authorId: user.uid,
      authorName: user.displayName || 'Anonymous',
      authorImage: user.photoURL || null,
      location: geoPoint,
      locationName: locationName,
      createdAt: articleData.createdAt || new Date(),
      updatedAt: new Date(),
      type: articleData.type || 'article',
      isImagePost: articleData.isImagePost || false,
      commentCount: articleData.commentCount || 0,
      shareCount: articleData.shareCount || 0,
      likes: articleData.likes || 0,
      likedBy: articleData.likedBy || [],
      columnId: articleData.columnId || 'my-column',
      columnTitle: articleData.columnTitle || 'My Column'
    };
    
    // Add the article to Firestore
    await setDoc(tempDocRef, articleWithLocation);
    return { id: articleId, ...articleWithLocation };
  } catch (error) {
    console.error('Error saving article with location:', error);
    throw error;
  }
};

// Function to get articles within a specific radius
export const getArticlesWithinRadius = async (radius = 100) => {
  try {
    // Get user's current location
    const location = await getUserLocation();
    
    // Get all articles (in a real app, you would implement pagination)
    const articlesRef = collection(db, 'articles');
    const q = query(articlesRef, orderBy('createdAt', 'desc'), limit(50));
    const querySnapshot = await getDocs(q);
    
    // Filter articles by distance
    const articlesWithinRadius = [];
    
    querySnapshot.forEach((doc) => {
      const article = { id: doc.id, ...doc.data() };
      
      // Calculate distance if the article has location data
      if (article.location) {
        const distance = calculateDistance(
          location.latitude,
          location.longitude,
          article.location.latitude,
          article.location.longitude
        );
        
        // Add article if it's within the specified radius
        if (distance <= radius) {
          articlesWithinRadius.push({
            ...article,
            distance: Math.round(distance) // Round to nearest km
          });
        }
      }
    });
    
    return articlesWithinRadius;
  } catch (error) {
    console.error('Error getting articles within radius:', error);
    throw error;
  }
};

// Function to update user's preferred location radius
export const updateUserLocationRadius = async (radius) => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    
    if (!user) {
      throw new Error('User must be logged in to update preferences');
    }
    
    // Update user's preferences in Firestore
    const userRef = collection(db, 'users');
    const q = query(userRef, where('uid', '==', user.uid));
    const querySnapshot = await getDocs(q);
    
    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0];
      await updateDoc(userDoc.ref, {
        locationRadius: radius
      });
    }
    
    return { success: true };
  } catch (error) {
    console.error('Error updating user location radius:', error);
    throw error;
  }
};

export default {
  getUserLocation,
  getLocationName,
  saveArticleWithLocation,
  getArticlesWithinRadius,
  updateUserLocationRadius
};

import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { db } from "./config";

export const deleteAllArticles = async () => {
  try {
    // جلب جميع المقالات
    const articlesRef = collection(db, "articles");
    const querySnapshot = await getDocs(articlesRef);
    
    // حذف كل مقال
    const deletePromises = querySnapshot.docs.map(doc => deleteDoc(doc.ref));
    await Promise.all(deletePromises);
    
    console.log(`تم حذف ${querySnapshot.size} مقال بنجاح`);
    return { success: true, count: querySnapshot.size };
  } catch (error) {
    console.error("خطأ في حذف المقالات:", error);
    throw error;
  }
}; 
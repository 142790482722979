import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import AuthModal from './AuthModal';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const Layout = () => {
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && user.emailVerified) {
        setCurrentUser(user);
        setShowAuthModal(false);
      } else {
        setCurrentUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  const protectedRoutes = ['/profile', '/write', '/bookmarks', '/ad'];

  useEffect(() => {
    if (protectedRoutes.includes(location.pathname) && !currentUser) {
      setShowAuthModal(true);
    }
  }, [location.pathname, currentUser]);

  return (
    <>
      <Header
        openAuthModal={() => setShowAuthModal(true)}
        user={currentUser}
      />
      <main>
        <Outlet />
      </main>
      <Footer />
      <AuthModal
        isOpen={showAuthModal}
        onClose={() => setShowAuthModal(false)}
      />
    </>
  );
};

export default Layout;

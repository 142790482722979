import { getFirestore, collection, addDoc, query, where, getDocs, updateDoc, deleteDoc, doc, arrayUnion, arrayRemove, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

// Initialize Firestore from the Firebase app
const db = getFirestore();

// Function to bookmark an article
export const bookmarkArticle = async (articleId) => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    
    if (!user) {
      throw new Error('User must be logged in to bookmark articles');
    }
    
    // Check if user document exists
    const userRef = collection(db, 'users');
    const q = query(userRef, where('uid', '==', user.uid));
    const querySnapshot = await getDocs(q);
    
    if (querySnapshot.empty) {
      // Create user document if it doesn't exist
      await addDoc(collection(db, 'users'), {
        uid: user.uid,
        displayName: user.displayName || 'Anonymous',
        email: user.email,
        bookmarks: [articleId]
      });
    } else {
      // Update existing user document
      const userDoc = querySnapshot.docs[0];
      await updateDoc(userDoc.ref, {
        bookmarks: arrayUnion(articleId)
      });
    }
    
    return { success: true, message: 'Article bookmarked successfully' };
  } catch (error) {
    console.error('Error bookmarking article:', error);
    throw error;
  }
};

// Function to remove bookmark from an article
export const removeBookmark = async (articleId) => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    
    if (!user) {
      throw new Error('User must be logged in to remove bookmarks');
    }
    
    // Find user document
    const userRef = collection(db, 'users');
    const q = query(userRef, where('uid', '==', user.uid));
    const querySnapshot = await getDocs(q);
    
    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0];
      await updateDoc(userDoc.ref, {
        bookmarks: arrayRemove(articleId)
      });
    }
    
    return { success: true, message: 'Bookmark removed successfully' };
  } catch (error) {
    console.error('Error removing bookmark:', error);
    throw error;
  }
};

// Function to check if an article is bookmarked by the current user
export const isArticleBookmarked = async (articleId) => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    
    if (!user) {
      return false;
    }
    
    // Find user document
    const userRef = collection(db, 'users');
    const q = query(userRef, where('uid', '==', user.uid));
    const querySnapshot = await getDocs(q);
    
    if (querySnapshot.empty) {
      return false;
    }
    
    const userDoc = querySnapshot.docs[0];
    const userData = userDoc.data();
    
    return userData.bookmarks && userData.bookmarks.includes(articleId);
  } catch (error) {
    console.error('Error checking bookmark status:', error);
    return false;
  }
};

// Function to get all bookmarked articles for the current user
export const getBookmarkedArticles = async () => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    
    if (!user) {
      throw new Error('User must be logged in to view bookmarks');
    }
    
    // Find user document
    const userRef = collection(db, 'users');
    const q = query(userRef, where('uid', '==', user.uid));
    const querySnapshot = await getDocs(q);
    
    if (querySnapshot.empty) {
      return [];
    }
    
    const userDoc = querySnapshot.docs[0];
    const userData = userDoc.data();
    
    if (!userData.bookmarks || userData.bookmarks.length === 0) {
      return [];
    }
    
    // Get all bookmarked articles
    const bookmarkedArticles = [];
    
    for (const articleId of userData.bookmarks) {
      const articleDoc = await getDoc(doc(db, 'articles', articleId));
      
      if (articleDoc.exists()) {
        bookmarkedArticles.push({
          id: articleDoc.id,
          ...articleDoc.data()
        });
      }
    }
    
    return bookmarkedArticles;
  } catch (error) {
    console.error('Error getting bookmarked articles:', error);
    throw error;
  }
};

// Function to share an article (record share event)
export const shareArticle = async (articleId, shareMethod) => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    
    // Get article reference
    const articleRef = doc(db, 'articles', articleId);
    const articleDoc = await getDoc(articleRef);
    
    if (!articleDoc.exists()) {
      throw new Error('Article not found');
    }
    
    // Record share event
    await addDoc(collection(db, 'shares'), {
      articleId,
      shareMethod,
      userId: user ? user.uid : null,
      timestamp: new Date()
    });
    
    // Update share count on the article
    const articleData = articleDoc.data();
    const shareCount = articleData.shareCount || 0;
    
    await updateDoc(articleRef, {
      shareCount: shareCount + 1
    });
    
    return { success: true, message: 'Article shared successfully' };
  } catch (error) {
    console.error('Error sharing article:', error);
    throw error;
  }
};

// Function to get share URL for an article
export const getShareUrl = (articleId) => {
  // In a real app, this would be a proper URL to your deployed site
  return `https://mycolu.com/article/${articleId}`;
};

// Function to share article via different platforms
export const shareViaMethod = async (articleId, title, method) => {
  const shareUrl = getShareUrl(articleId);
  
  try {
    switch (method) {
      case 'twitter':
        window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURIComponent(shareUrl)}`, '_blank');
        break;
      case 'facebook':
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`, '_blank');
        break;
      case 'linkedin':
        window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}`, '_blank');
        break;
      case 'email':
        window.location.href = `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(`Check out this article: ${shareUrl}`)}`;
        break;
      case 'copy':
        await navigator.clipboard.writeText(shareUrl);
        break;
      default:
        throw new Error('Invalid share method');
    }
    
    // Record share event
    await shareArticle(articleId, method);
    
    return { success: true, message: `Shared via ${method}` };
  } catch (error) {
    console.error(`Error sharing via ${method}:`, error);
    throw error;
  }
};

export default {
  bookmarkArticle,
  removeBookmark,
  isArticleBookmarked,
  getBookmarkedArticles,
  shareArticle,
  getShareUrl,
  shareViaMethod
};

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getArticles } from '../services/firebase/articles';
import { deleteAllArticles } from '../services/firebase/deleteAllArticles';
import { getAuth } from 'firebase/auth';
import { deleteDoc, doc } from 'firebase/firestore';
import { db } from '../services/firebase/config';
import '../styles/global.css';
import '../styles/homepageLayout.css';

// دالة مساعدة لتنسيق التاريخ
const formatDate = (date) => {
  if (!date) return '';
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(date).toLocaleDateString('ar-SA', options);
};

// دالة مساعدة لاستخراج أول صورة من المحتوى
const extractFirstImage = (content) => {
  if (!content) return null;
  
  // محاولة العثور على الصورة في خاصية image أولاً
  if (typeof content === 'object' && content.image) {
    return content.image;
  }

  // البحث عن الصور في المحتوى HTML
  const patterns = [
    /<img[^>]+src="([^">]+)"/i,                    // نمط عادي للصور
    /<img[^>]+src='([^'>]+)'/i,                    // نمط مع علامات اقتباس فردية
    /background-image:\s*url\(['"]?([^'")\s]+)/i,  // نمط CSS background-image
    /url\(['"]?([^'")\s]+)/i                       // نمط URL عام
  ];

  for (const pattern of patterns) {
    const match = content.match(pattern);
    if (match && match[1]) {
      // تنظيف رابط الصورة
      let imageUrl = match[1].trim();
      
      // إزالة علامات الاقتباس إذا وجدت
      imageUrl = imageUrl.replace(/['"]/g, '');
      
      // التحقق من صحة الرابط
      if (imageUrl.startsWith('http') || imageUrl.startsWith('https') || imageUrl.startsWith('/')) {
        return imageUrl;
      }
    }
  }

  return null;
};

// دالة مساعدة لتنظيف النص من HTML
const stripHtml = (html) => {
  if (!html) return '';
  return html.replace(/<[^>]*>/g, '');
};

// دالة مساعدة لإنشاء مقتطف
const createExcerpt = (content, length = 2000) => {
  if (!content) return '';
  const strippedContent = stripHtml(content);
  return strippedContent.length > length ? 
    strippedContent.substring(0, length) + '...' : 
    strippedContent;
};

// دالة مساعدة لتقصير العنوان
const truncateTitle = (title, maxLength = 30) => {
  if (!title) return '';
  return title.length > maxLength ? title.substring(0, maxLength) + '...' : title;
};

// مكون فرعي لعرض مقتطف المقال - تم تعديله بناءً على الحل المقترح
const ArticleSnippet = ({ article, layoutType = 'full', className = '', isAdmin = false }) => {
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);
  
  const safeArticle = {
    id: article?.id || `placeholder-${Math.random()}`,
    title: article?.title || '',
    author: article?.author || '',
    authorImage: article?.authorImage,
    columnTitle: article?.columnTitle || '',
    content: article?.content || '',
    image: article?.image || extractFirstImage(article?.content),
    createdAt: article?.createdAt,
  };

  const handleArticleClick = (e) => {
    e.preventDefault();
    if (!safeArticle.id.startsWith('placeholder-')) {
      navigate(`/article/${safeArticle.id}`);
    }
  };

  const handleProfileClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/profile/${safeArticle.author}`);
  };

  const handleEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/write?edit=${safeArticle.id}`);
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (!window.confirm('هل أنت متأكد من حذف هذا المقال؟')) {
      return;
    }

    try {
      setIsDeleting(true);
      await deleteDoc(doc(db, 'articles', safeArticle.id));
      window.location.reload(); // تحديث الصفحة بعد الحذف
    } catch (error) {
      console.error('خطأ في حذف المقال:', error);
      alert('حدث خطأ أثناء حذف المقال');
    } finally {
      setIsDeleting(false);
    }
  };

  const showImage = layoutType !== 'text-only' && safeArticle.image;
  const { title } = safeArticle;
  const [mainTitle, subTitle] = title.includes(' to ') ? 
    [title.split(' to ')[0], 'to ' + title.split(' to ')[1]] : 
    [title, ''];

  const AdminButtons = () => isAdmin && (
    <div className="admin-actions" style={{
      position: 'absolute',
      top: '10px',
      right: '10px',
      display: 'flex',
      gap: '10px',
      zIndex: 1
    }}>
      <button
        onClick={handleEdit}
        style={{
          padding: '5px 10px',
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer'
        }}
      >
        تعديل
      </button>
      <button
        onClick={handleDelete}
        disabled={isDeleting}
        style={{
          padding: '5px 10px',
          backgroundColor: '#f44336',
          color: 'white',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
          opacity: isDeleting ? 0.7 : 1
        }}
      >
        {isDeleting ? 'جاري الحذف...' : 'حذف'}
      </button>
    </div>
  );

  return (
    <article className={`article-snippet ${!showImage ? 'no-image' : ''} layout-${layoutType} ${className}`} style={{ position: 'relative' }}>
      <AdminButtons />
      {layoutType === 'full' ? (
        <>
          <div className="article-header">
            <div className="article-title">
              <a href={`/article/${safeArticle.id}`} onClick={handleArticleClick}>
                <div className="article-title-main" title={safeArticle.title}>
                  {truncateTitle(safeArticle.title)}
                </div>
              </a>
            </div>
            <div className="article-meta">
              <div className="article-column-title" onClick={handleProfileClick}>
                {safeArticle.columnTitle}
              </div>
              <div className="article-author-info" onClick={handleProfileClick}>
                {safeArticle.authorImage && (
                  <img
                    src={safeArticle.authorImage}
                    alt={safeArticle.author}
                    className="author-image"
                    onError={(e) => { e.target.style.display = 'none'; }}
                  />
                )}
                <div className="author-text">
                  <div className="author-name">{safeArticle.author}</div>
                  {safeArticle.createdAt && (
                    <div className="article-date">
                      {formatDate(safeArticle.createdAt)}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="article-content-row">
            <div className="article-excerpt-wrapper">
              <div className="article-excerpt">
                {createExcerpt(safeArticle.content)}
              </div>
            </div>

            {showImage && (
              <div className="article-image-container">
                <img
                  src={safeArticle.image}
                  alt={safeArticle.title}
                  className="article-image"
                  onError={(e) => { e.target.style.display = 'none'; }}
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="article-header">
            <div className="article-title">
              <a href={`/article/${safeArticle.id}`} onClick={handleArticleClick}>
                <div className="article-title-main" title={safeArticle.title}>
                  {truncateTitle(safeArticle.title)}
                </div>
              </a>
            </div>
            <div className="article-meta">
              <div className="article-column-title" onClick={handleProfileClick}>
                {safeArticle.columnTitle}
              </div>
              <div className="article-author-info" onClick={handleProfileClick}>
                {safeArticle.authorImage && (
                  <img
                    src={safeArticle.authorImage}
                    alt={safeArticle.author}
                    className="author-image"
                    onError={(e) => { e.target.style.display = 'none'; }}
                  />
                )}
                <div className="author-text">
                  <div className="author-name">{safeArticle.author}</div>
                  {safeArticle.createdAt && (
                    <div className="article-date">
                      {formatDate(safeArticle.createdAt)}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="article-excerpt">
            {createExcerpt(safeArticle.content)}
          </div>

          {showImage && (
            <div className="article-image-container">
              <img
                src={safeArticle.image}
                alt={safeArticle.title}
                className="article-image"
                onError={(e) => { e.target.style.display = 'none'; }}
              />
            </div>
          )}
        </>
      )}
    </article>
  );
};

// مكون فرعي لعرض قسم الصورة المميزة
const ImageFeature = ({ imagePost }) => {
  const navigate = useNavigate();
  
  const handleImageClick = () => {
    if (imagePost?.id) {
      navigate(`/article/${imagePost.id}`);
    }
  };

  const handleProfileClick = (e) => {
    e.stopPropagation();
    if (imagePost?.author) {
      navigate(`/profile/${imagePost.author}`);
    }
  };

  return (
    <div className="image-feature-container" onClick={handleImageClick}>
      <img
        src={imagePost?.image}
        alt={imagePost?.columnTitle || 'Featured Image'}
        className="image-feature-img"
        onError={(e) => { e.target.src = '/images/placeholder-large.png'; }}
      />
      <div className="image-feature-caption">
        <div className="article-author-info" onClick={handleProfileClick}>
          {imagePost?.authorImage && (
            <img
              src={imagePost.authorImage}
              alt={imagePost.author}
              className="author-image"
              onError={(e) => { e.target.style.display = 'none'; }}
            />
          )}
          <div className="author-text">
            <div className="author-name">{imagePost?.author}</div>
            <div className="article-column-title">{imagePost?.columnTitle}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

// دالة مساعدة لتجميع المقالات في أنماط
const groupArticlesForPatterns = (articles, imagePosts) => {
  const patterns = [];
  let currentIndex = 0;

  while (currentIndex < articles.length) {
    const patternArticles = articles.slice(currentIndex, currentIndex + 5);
    const imagePost = imagePosts[Math.floor(currentIndex / 5)];
    
    if (patternArticles.length > 0) {
      patterns.push({
        articles: patternArticles,
        imageFeature: imagePost
      });
    }
    
    currentIndex += 5;
  }

  return patterns;
};

// المكون الرئيسي للصفحة
const HomePage = () => {
  const [articles, setArticles] = useState([]);
  const [imagePosts, setImagePosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [isAdmin, setIsAdmin] = useState(false);
  const observer = useRef();
  const lastArticleRef = useRef();

  const articlesPerPage = 10;

  useEffect(() => {
    const auth = getAuth();
    // تحقق مما إذا كان المستخدم مسؤولاً
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        // تغيير البريد الإلكتروني للمسؤول
        setIsAdmin(user.email === 'ana9non@gmail.com');
      } else {
        setIsAdmin(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleDeleteAllArticles = async () => {
    if (!window.confirm('هل أنت متأكد من حذف جميع المقالات؟ لا يمكن التراجع عن هذا الإجراء.')) {
      return;
    }

    try {
      setLoading(true);
      const result = await deleteAllArticles();
      setArticles([]);
      setImagePosts([]);
      alert(`تم حذف ${result.count} مقال بنجاح`);
    } catch (error) {
      console.error('خطأ في حذف المقالات:', error);
      alert('حدث خطأ أثناء حذف المقالات');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0
    };

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore && !loading) {
        setPage(prev => prev + 1);
      }
    }, options);

    if (lastArticleRef.current) {
      observer.current.observe(lastArticleRef.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [hasMore, loading]);

  const fetchArticles = async (pageNumber) => {
    try {
      setLoading(true);
      setError(null);
      
      const fetchedArticles = await getArticles(articlesPerPage, (pageNumber - 1) * articlesPerPage);
      
      const imageOnlyPosts = fetchedArticles.filter(article => 
        article.type === 'image' || article.isImagePost === true
      );
      const normalArticles = fetchedArticles.filter(article => 
        article.type !== 'image' && !article.isImagePost
      );

      if (pageNumber === 1) {
        setImagePosts(imageOnlyPosts);
        setArticles(normalArticles);
      } else {
        setImagePosts(prev => [...prev, ...imageOnlyPosts]);
        setArticles(prev => [...prev, ...normalArticles]);
      }
      
      setHasMore(fetchedArticles.length === articlesPerPage);
    } catch (err) {
      console.error("Error fetching articles:", err);
      setError("حدث خطأ أثناء تحميل المقالات");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchArticles(1);
  }, []);

  useEffect(() => {
    if (page > 1) {
      fetchArticles(page);
    }
  }, [page]);

  const renderPatternBlocks = () => {
    const groupedData = groupArticlesForPatterns(articles, imagePosts);

    return groupedData.map((patternData, patternIndex) => (
      <React.Fragment key={`pattern-${patternIndex}`}>
        <section className="article-pattern">
          {/* المقال الرئيسي */}
          <div className="pattern-row pattern-row-1">
            {patternData.articles[0] && (
              <ArticleSnippet article={patternData.articles[0]} layoutType="full" isAdmin={isAdmin} />
            )}
          </div>

          <hr className="pattern-divider horizontal" />

          {/* صف الإعلانات */}
          <div className="pattern-row pattern-row-2">
            <div className="ad-block ad-block-medium">ADVERTISEMENT</div>
            <div className="ad-block ad-block-medium">ADVERTISEMENT</div>
          </div>

          <hr className="pattern-divider horizontal" />

          {/* المقالان جنباً إلى جنب */}
          <div className="pattern-row pattern-row-3">
            {patternData.articles[1] && (
              <div>
                <ArticleSnippet 
                  article={patternData.articles[1]} 
                  layoutType="side" 
                  className={`style-left ${patternData.articles[1].image ? "has-image" : "no-image"}`}
                  isAdmin={isAdmin}
                />
              </div>
            )}
            <div className="pattern-divider vertical" />
            {patternData.articles[2] && (
              <div>
                <ArticleSnippet 
                  article={patternData.articles[2]} 
                  layoutType="side"
                  className={`style-right ${patternData.articles[2].image ? "has-image" : "no-image"}`}
                  isAdmin={isAdmin}
                />
              </div>
            )}
          </div>

          <hr className="pattern-divider horizontal" />

          {/* الصف المعقد */}
          <div className="pattern-row pattern-row-4-5">
            <div className="pattern-column-4">
              {patternData.articles[3] && (
                <ArticleSnippet 
                  article={patternData.articles[3]} 
                  layoutType="text-only" 
                  isAdmin={isAdmin}
                />
              )}
              <hr className="pattern-divider horizontal" />
              {patternData.imageFeature && (
                <ImageFeature imagePost={patternData.imageFeature} />
              )}
            </div>
            <div className="pattern-divider vertical" />
            <div className="ad-block ad-block-narrow">ADVERTISEMENT</div>
            <div className="pattern-divider vertical" />
            <div className="pattern-column-5">
              {patternData.articles[4] && (
                <ArticleSnippet 
                  article={patternData.articles[4]} 
                  layoutType="tall" 
                  isAdmin={isAdmin}
                />
              )}
            </div>
          </div>
        </section>

        {patternIndex < groupedData.length - 1 && (
          <hr className="pattern-divider pattern-separator" />
        )}
      </React.Fragment>
    ));
  };

  return (
    <div className="homepage-container">
      {isAdmin && (
        <div style={{ marginBottom: '20px', textAlign: 'center' }}>
          <button 
            onClick={handleDeleteAllArticles}
            style={{
              padding: '10px 20px',
              backgroundColor: '#ff4444',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
            disabled={loading}
          >
            {loading ? 'جاري الحذف...' : 'حذف جميع المقالات'}
          </button>
        </div>
      )}
      
      {articles.length === 0 && loading && (
        <div className="loading-indicator">جاري تحميل المقالات...</div>
      )}
      
      {error && (
        <div className="error-message">{error}</div>
      )}
      
      {!loading && articles.length === 0 && !error && (
        <div className="no-articles-message">لا توجد مقالات حتى الآن</div>
      )}
      
      {articles.length > 0 && renderPatternBlocks()}
      
      <div ref={lastArticleRef} style={{ height: '10px', margin: '20px 0' }} />
      
      {loading && articles.length > 0 && (
        <div className="loading-more">جاري تحميل المزيد...</div>
      )}
    </div>
  );
};

export default HomePage;
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { auth, db } from '../services/firebase/config';
import '../styles/global.css';

const haversineDistance = (lat1, lon1, lat2, lon2) => {
  const toRad = deg => (deg * Math.PI) / 180;
  const R = 6371;
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) ** 2 +
    Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
};

const ProfilePage = () => {
  const { id } = useParams();
  const [author, setAuthor] = useState(null);
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isCurrentUser, setIsCurrentUser] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [isInRange, setIsInRange] = useState(false);

  const bgStyle = {
    backgroundColor: '#f9f7f2',
    minHeight: '100vh'
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        setUserLocation({
          latitude: pos.coords.latitude,
          longitude: pos.coords.longitude
        });
      },
      (err) => {
        console.error("Location error:", err);
        setUserLocation(null);
      }
    );
  }, []);

  useEffect(() => {
    const loadData = async () => {
      try {
        // إذا كان المستخدم الحالي هو نفسه المستخدم المطلوب عرض صفحته
        const current = auth.currentUser;
        if (current && current.uid === id) {
          setIsCurrentUser(true);
          
          // إنشاء بيانات افتراضية للمستخدم الحالي إذا لم تكن موجودة
          const userRef = doc(db, 'users', id);
          const userSnap = await getDoc(userRef);
          
          if (userSnap.exists()) {
            const userData = userSnap.data();
            setAuthor(userData);
          } else {
            // إنشاء بيانات افتراضية للمستخدم
            const defaultUserData = {
              displayName: current.displayName || 'User',
              photoURL: current.photoURL || '/images/default-user.png',
              columnTitle: 'My Column',
              bio: 'Welcome to my column. Edit your profile to add a bio.',
              social: {}
            };
            setAuthor(defaultUserData);
          }
        } else {
          // إذا كان المستخدم المطلوب ليس المستخدم الحالي
          const userRef = doc(db, 'users', id);
          const userSnap = await getDoc(userRef);
          
          if (!userSnap.exists()) throw new Error('User not found');
          const userData = userSnap.data();
          setAuthor(userData);
        }

        // جلب مقالات المستخدم
        const q = query(collection(db, 'articles'), where('authorId', '==', id));
        const articlesSnap = await getDocs(q);
        const userArticles = articlesSnap.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setArticles(userArticles);

        // إزالة التحقق من الموقع الجغرافي
        setIsInRange(true);

      } catch (err) {
        console.error(err);
        // إذا كان المستخدم الحالي هو نفسه المستخدم المطلوب، نعرض بيانات افتراضية
        if (auth.currentUser && auth.currentUser.uid === id) {
          const defaultUserData = {
            displayName: auth.currentUser.displayName || 'User',
            photoURL: auth.currentUser.photoURL || '/images/default-user.png',
            columnTitle: 'My Column',
            bio: 'Welcome to my column. Edit your profile to add a bio.',
            social: {}
          };
          setAuthor(defaultUserData);
          setIsCurrentUser(true);
        } else {
          setAuthor(null);
        }
      } finally {
        setLoading(false);
      }
    };

    // إزالة الاعتماد على الموقع الجغرافي
    loadData();
  }, [id]);

  const toggleSettingsModal = () => {
    setShowSettingsModal(!showSettingsModal);
  };

  const ArticleCard = ({ article }) => (
    <div className="article">
      <div className="article-column-title">{article.columnTitle}</div>
      <h2 className="article-title">{article.title}</h2>
      <p className="article-author">BY {article.author}</p>
      {article.image && <img src={article.image} alt={article.title} className="article-image" />}
      <p className="article-content">{article.excerpt}</p>
      <Link to={`/article/${article.id}`} className="article-link">Read Article</Link>
    </div>
  );

  if (loading) return <div className="container">Loading...</div>;
  if (!author) return <div className="container">Author not found</div>;

  if (!isInRange && !isCurrentUser) {
    return (
      <div className="container" style={{ padding: '3rem 1rem', textAlign: 'center' }}>
        <h2>This column is not available in your area.</h2>
        <p>You must be within 100 km of the author's location to view this profile.</p>
      </div>
    );
  }

  return (
    <div style={bgStyle}>
      <div className="container">
        <section className="profile-header" style={{ textAlign: 'center', margin: '2rem 0' }}>
          <div className="social-icons">
            {author.social?.instagram && <a href={author.social.instagram} target="_blank" rel="noreferrer">IG</a>}
            {author.social?.twitter && <a href={author.social.twitter} target="_blank" rel="noreferrer">TW</a>}
            {author.social?.youtube && <a href={author.social.youtube} target="_blank" rel="noreferrer">YT</a>}
            {author.social?.tiktok && <a href={author.social.tiktok} target="_blank" rel="noreferrer">TK</a>}
          </div>

          <h2 className="profile-column-title">{author.columnTitle}</h2>

          <div className="profile-author">
            <img src={author.photoURL} alt={author.displayName} className="profile-author-image" />
            <p className="profile-author-name">{author.displayName}</p>
          </div>

          <p className="profile-bio">{author.bio}</p>

          {isCurrentUser && (
            <button onClick={toggleSettingsModal} className="settings-button">Edit Profile</button>
          )}
        </section>

        <h2 style={{ marginBottom: '1rem' }}>ARTICLES</h2>
        <div className="article-grid">
          {articles.map(article => (
            <ArticleCard key={article.id} article={article} />
          ))}
        </div>

        {showSettingsModal && (
          <div className="settings-modal" onClick={toggleSettingsModal}>
            <div className="settings-modal-content" onClick={e => e.stopPropagation()}>
              <h3>تعديل الملف الشخصي</h3>
              <div className="settings-form">
                <div className="settings-form-group">
                  <label className="settings-label">صورة الملف الشخصي</label>
                  <div className="profile-image-upload">
                    <img 
                      src={author.photoURL || '/images/default-user.png'} 
                      alt={author.displayName} 
                      className="profile-preview-image" 
                    />
                    <input 
                      type="text" 
                      placeholder="أدخل رابط الصورة" 
                      className="settings-input"
                      defaultValue={author.photoURL}
                    />
                  </div>
                </div>
                
                <div className="settings-form-group">
                  <label className="settings-label">اسم العمود</label>
                  <input 
                    type="text" 
                    className="settings-input" 
                    placeholder="أدخل اسم العمود الخاص بك"
                    defaultValue={author.columnTitle}
                  />
                </div>
                
                <div className="settings-form-group">
                  <label className="settings-label">نبذة عنك</label>
                  <textarea 
                    className="settings-textarea" 
                    placeholder="أدخل نبذة قصيرة عنك"
                    defaultValue={author.bio}
                    rows="4"
                  ></textarea>
                </div>
                
                <div className="settings-form-group">
                  <label className="settings-label">مواقع التواصل الاجتماعي</label>
                  <div className="social-inputs">
                    <div className="social-input-group">
                      <label>Instagram</label>
                      <input 
                        type="text" 
                        className="settings-input" 
                        placeholder="رابط Instagram"
                        defaultValue={author.social?.instagram}
                      />
                    </div>
                    <div className="social-input-group">
                      <label>Twitter</label>
                      <input 
                        type="text" 
                        className="settings-input" 
                        placeholder="رابط Twitter"
                        defaultValue={author.social?.twitter}
                      />
                    </div>
                    <div className="social-input-group">
                      <label>YouTube</label>
                      <input 
                        type="text" 
                        className="settings-input" 
                        placeholder="رابط YouTube"
                        defaultValue={author.social?.youtube}
                      />
                    </div>
                    <div className="social-input-group">
                      <label>TikTok</label>
                      <input 
                        type="text" 
                        className="settings-input" 
                        placeholder="رابط TikTok"
                        defaultValue={author.social?.tiktok}
                      />
                    </div>
                  </div>
                </div>
                
                <div className="settings-form-actions">
                  <button className="settings-button">حفظ التغييرات</button>
                  <button className="settings-button cancel" onClick={toggleSettingsModal}>إلغاء</button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfilePage;

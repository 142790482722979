import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/global.css';

const AboutPage = () => {
  return (
    <div className="container">
      <header className="header">
        <div className="header-top">
          <div className="header-ad">AD</div>
          <h1 className="header-logo">MyColu</h1>
          <div className="header-ad">AD</div>
        </div>
        <div className="header-info">
          <span>About the Project</span>
          <span>{new Date().toLocaleDateString('en-US')}</span>
        </div>
      </header>

      <main style={{ padding: '3rem 1rem', lineHeight: '1.8', fontSize: '1.1rem' }}>
        <h2 style={{ textAlign: 'center', marginBottom: '2rem', fontSize: '2rem' }}>About MyColu</h2>

        <p>
          <strong>MyColu</strong> is a modern take on classic journalism. Inspired by the structure and spirit of traditional print newspapers,
          this platform empowers individual writers to publish their own columns with style, integrity, and timeless presentation.
        </p>

        <p>
          Each author on MyColu owns a personal column space where ideas are expressed through articles, illustrations, opinions, and curated visuals.
          We believe in slow, thoughtful publishing – away from the distractions of social feeds and algorithmic noise.
        </p>

        <h3 style={{ marginTop: '2rem' }}>Mission</h3>
        <p>
          To create a digital sanctuary for column-based publishing. A place where ideas speak louder than trends, and layout respects the reader's eye.
        </p>

        <h3 style={{ marginTop: '2rem' }}>Disclaimer</h3>
        <p>
          All content published on MyColu is the sole responsibility of its author. The platform does not endorse or verify opinions or claims.
          We trust our writers and readers to foster a respectful, enriching environment.
        </p>

        <p style={{ marginTop: '2rem', fontStyle: 'italic' }}>
          Designed with the past in mind. Built for the thoughtful future.
        </p>
      </main>

      <footer className="footer">
        <p>&copy; {new Date().getFullYear()} MyColu. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default AboutPage;

import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill'; // استيراد ReactQuill
import 'react-quill/dist/quill.snow.css'; // استيراد تنسيقات Quill الأساسية (ثيم Snow)
import '../styles/global.css'; // ملف الأنماط العام
import '../styles/articleTemplates.css'; // ملف تنسيق عرض المقال النهائي (للتأكد من تطابق الأعمدة)
import '../styles/customQuillStyles.css'; // <<< ملف CSS الجديد الذي سننشئه

// مصفوفة القوالب - تم تحديث عدد الأعمدة ليطابق ملف articleTemplates.css
const templates = [
  { name: 'Standard Article', columns: 2 }, // يتطلب عمودين في العرض النهائي
  { name: 'Two Column', columns: 3 },      // يتطلب 3 أعمدة في العرض النهائي
  { name: 'Feature Article', columns: 1 }, // يتطلب عمودًا واحدًا في العرض النهائي
  { name: 'Opinion Piece', columns: 2 },  // يتطلب عمودين في العرض النهائي
  { name: 'Interview', columns: 1 }     // يتطلب عمودًا واحدًا في العرض النهائي
];

const CustomEditor = ({ initialContent = '', onSave, user }) => {
  const [content, setContent] = useState(initialContent);
  const [title, setTitle] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState(0);
  const [isSaving, setIsSaving] = useState(false);

  // تهيئة شريط أدوات Quill - بدون قوائم منسدلة
  const quillModules = {
    toolbar: [
      // [{ 'font': [] }], // يمكن إضافة خطوط إذا أردت
      [{ 'header': '1' }, { 'header': '2' }, { 'header': '3' }, 'blockquote'],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }], // محاذاة LTR
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'indent': '-1'}, { 'indent': '+1' }], // زيادة/تقليل المسافة البادئة
      // [{ 'color': [] }, { 'background': [] }], // يمكن إضافة ألوان
      ['link', 'image'], // يمكنك إضافة 'video' إذا أردت
      ['clean'] // إزالة التنسيق
    ],
  };

  // التنسيقات المدعومة من Quill (يجب أن تتطابق مع أزرار الشريط)
  const quillFormats = [
    'header', 'blockquote',
    'bold', 'italic', 'underline', 'strike',
    'align',
    'list', 'bullet',
    'indent',
    'link', 'image',
    // 'font', 'color', 'background' // أضف هذه إذا استخدمت الأزرار المقابلة
  ];

  // دالة الحفظ
  const handleSave = async () => {
    if (!title) {
      alert('Article title is required.');
      return;
    }
    // التحقق من وجود معلومات العمود للمستخدم (من Firebase Auth أو غيره)
    if (!user?.column) {
      alert('User column information is missing. Cannot save.');
      console.warn("User object or user.column is missing during save:", user);
      return;
    }

    setIsSaving(true);
    try {
      const articleData = {
        title,
        columnTitle: user.column,     // اسم العمود الخاص بالمستخدم
        columnId: user.columnId,      // معرف العمود
        author: user.name,            // اسم المؤلف
        authorUsername: user.username, // اسم مستخدم المؤلف
        content,                      // محتوى المقال من Quill
        template: templates[selectedTemplate], // معلومات القالب المختار
        createdAt: new Date(),        // تاريخ الإنشاء
        type: 'article',              // نوع المحتوى (مقال عادي)
        isImagePost: false,           // ليس منشور صورة
        commentCount: 0,              // عدد التعليقات الأولي
        shareCount: 0,                // عدد المشاركات الأولي
        likes: 0,                     // عدد الإعجابات الأولي
        likedBy: []                   // قائمة المستخدمين الذين أعجبوا بالمقال
      };

      console.log("Saving article data:", articleData);
      await onSave(articleData);
    } catch (error) {
      console.error("Error in handleSave:", error);
      alert('Failed to save article. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  // دالة لتغيير القالب المختار
  const handleTemplateSelect = (index) => {
    setSelectedTemplate(index);
  };

  // الحصول على اسم كلاس CSS للقالب لتطبيقه على حاوية المحرر (للمعاينة)
  const getEditorWrapperClass = () => {
    // استخدام اسم القالب لإنشاء كلاس CSS متوافق
    const templateName = templates[selectedTemplate].name.toLowerCase().replace(/\s+/g, '-');
    // إضافة كلاس الأعمدة للمعاينة داخل المحرر
    return `editor-quill-wrapper template-${templateName} columns-${templates[selectedTemplate].columns}`;
  };

  return (
    // إضافة كلاس للثيم الكلاسيكي المطلوب
    <div className="editor-container classic-theme">

      {/* --- رأس المحرر: العنوان واختيار القالب --- */}
      <div className="editor-header">
        {/* حقل العنوان */}
        <div className="form-group">
          <label className="settings-label" htmlFor="articleTitle">Article Title</label>
          <input
            id="articleTitle"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="settings-input title-input"
            placeholder="Enter your article title here..."
          />
        </div>

        {/* أزرار اختيار القالب */}
        <div className="editor-templates-selection">
          <label className="settings-label">Select Template:</label>
          <div className="template-buttons-container">
            {templates.map((template, index) => (
              <button
                key={index}
                className={`template-button ${selectedTemplate === index ? 'active' : ''}`}
                onClick={() => handleTemplateSelect(index)}
                // تلميح يوضح تفاصيل القالب عند المرور بالفأرة
                title={`${template.name} - Preview: ${template.columns} column${template.columns > 1 ? 's' : ''}`}
              >
                {template.name}
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* --- محرر Quill --- */}
      {/* الحاوية التي ستأخذ تنسيق الأعمدة للمعاينة */}
      <div className={getEditorWrapperClass()}>
        <ReactQuill
          theme="snow" // استخدام ثيم "snow" المدمج كقاعدة
          value={content}
          onChange={setContent} // تحديث حالة المحتوى عند التغيير
          modules={quillModules} // تطبيق شريط الأدوات المخصص
          formats={quillFormats} // تحديد التنسيقات المسموح بها
          placeholder="Start writing your masterpiece..." // نص مؤقت يظهر عندما يكون المحرر فارغًا
          style={{ backgroundColor: '#fff' }} // خلفية بيضاء للمحرر نفسه
        />
      </div>

      {/* زر النشر */}
      <div className="editor-footer">
        <button 
          onClick={handleSave} 
          className="settings-button publish-button" 
          disabled={!title || !content || isSaving}
        >
          {isSaving ? 'Publishing...' : 'Publish Article'}
        </button>
      </div>

    </div>
  );
};

export default CustomEditor;
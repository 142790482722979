import React, { useState } from 'react';
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile
} from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../services/firebase/config';
import '../styles/global.css';
import '../styles/authModal.css';

const AuthModal = ({ isOpen, onClose, initialTab = 'login' }) => {
  const [activeTab, setActiveTab] = useState(initialTab);
  const [formData, setFormData] = useState({
    login: { email: '', password: '' },
    register: { name: '', email: '', password: '', confirmPassword: '' }
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  if (!isOpen) return null;

  const handleChange = (e, formType) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [formType]: {
        ...formData[formType],
        [name]: value
      }
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const auth = getAuth();
      const { email, password } = formData.login;
      const userCred = await signInWithEmailAndPassword(auth, email, password);

      if (!userCred.user.emailVerified) {
        setError('Please verify your email before logging in.');
        setLoading(false);
        return;
      }

      onClose(); // success
    } catch (err) {
      setError('Invalid credentials or user does not exist.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const { name, email, password, confirmPassword } = formData.register;

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      setLoading(false);
      return;
    }

    try {
      const auth = getAuth();
      const userCred = await createUserWithEmailAndPassword(auth, email, password);

      if (!userCred || !userCred.user) {
        throw new Error('User creation failed.');
      }

      await updateProfile(userCred.user, { displayName: name });
      await sendEmailVerification(userCred.user);

      await setDoc(doc(db, 'users', userCred.user.uid), {
        displayName: name,
        email: email,
        columnTitle: 'My Column',
        columnStyle: 'primary-font',
        bio: '',
        photoURL: userCred.user.photoURL || '',
        location: null
      });

      alert('Registration successful! Please check your email to verify your account.');
      onClose();
    } catch (err) {
      console.error(err);
      if (err.code === 'auth/email-already-in-use') {
        setError('This email is already registered. Please try logging in.');
      } else if (err.code === 'auth/invalid-email') {
        setError('Invalid email address.');
      } else if (err.code === 'auth/weak-password') {
        setError('Password should be at least 6 characters.');
      } else {
        setError('Failed to register. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="auth-modal" onClick={onClose}>
      <div className="auth-modal-content" onClick={e => e.stopPropagation()}>
        <div className="auth-tabs">
          <div className={`auth-tab ${activeTab === 'login' ? 'active' : ''}`} onClick={() => setActiveTab('login')}>Login</div>
          <div className={`auth-tab ${activeTab === 'register' ? 'active' : ''}`} onClick={() => setActiveTab('register')}>Register</div>
        </div>

        {error && (
          <div style={{ color: 'red', marginBottom: '1rem', background: '#ffeeee', padding: '0.5rem' }}>
            {error}
          </div>
        )}

        {activeTab === 'login' ? (
          <form onSubmit={handleLogin}>
            <div className="auth-form-group">
              <label>Email</label>
              <input
                type="email"
                name="email"
                className="auth-input"
                value={formData.login.email}
                onChange={(e) => handleChange(e, 'login')}
                required
              />
            </div>
            <div className="auth-form-group">
              <label>Password</label>
              <input
                type="password"
                name="password"
                className="auth-input"
                value={formData.login.password}
                onChange={(e) => handleChange(e, 'login')}
                required
              />
            </div>
            <button type="submit" className="auth-button" disabled={loading}>
              {loading ? 'Logging in...' : 'Login'}
            </button>
          </form>
        ) : (
          <form onSubmit={handleRegister}>
            <div className="auth-form-group">
              <label>Name</label>
              <input
                type="text"
                name="name"
                className="auth-input"
                value={formData.register.name}
                onChange={(e) => handleChange(e, 'register')}
                required
              />
            </div>
            <div className="auth-form-group">
              <label>Email</label>
              <input
                type="email"
                name="email"
                className="auth-input"
                value={formData.register.email}
                onChange={(e) => handleChange(e, 'register')}
                required
              />
            </div>
            <div className="auth-form-group">
              <label>Password</label>
              <input
                type="password"
                name="password"
                className="auth-input"
                value={formData.register.password}
                onChange={(e) => handleChange(e, 'register')}
                required
              />
            </div>
            <div className="auth-form-group">
              <label>Confirm Password</label>
              <input
                type="password"
                name="confirmPassword"
                className="auth-input"
                value={formData.register.confirmPassword}
                onChange={(e) => handleChange(e, 'register')}
                required
              />
            </div>
            <button type="submit" className="auth-button" disabled={loading}>
              {loading ? 'Registering...' : 'Register'}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default AuthModal;

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../styles/global.css';
import '../styles/Header.css';

const Header = ({ openAuthModal, user }) => {
  const location = useLocation();

  const handleProtectedClick = (e, path) => {
    if (!user && ['/profile', '/write', '/bookmarks', '/ad'].includes(path)) {
      e.preventDefault();
      openAuthModal();
    }
  };

  // أنماط الخطوط باستخدام الخلفية بدلاً من صور img
  const lineAboveStyle = {
    backgroundImage: `url('/images/line3.png')`,
  };

  const lineBelowStyle = {
    backgroundImage: `url('/images/line4.png')`,
  };

  return (
    <>
      <div className="header-wrapper">
        <div className="header">
          <div className="header-info">
            <span>Location</span>
            <span>{new Date().toLocaleDateString()}</span>
          </div>

          <div className="header-content">
            <div className="header-ad-container">
              <div className="header-ad">Ad Space</div>
              <div className="logo-area">
                <Link to="/">
                  <img src="/images/logo.png" alt="MyColu" className="logo-image" />
                </Link>
              </div>
              <div className="header-ad">Ad Space</div>
            </div>
            <img src="/images/line1.png" alt="" className="line-image-top-right" />
            <img src="/images/line2.png" alt="" className="line-image-top-left" />
          </div>

          <div className="nav-container">
            <div className="line-image-above-nav" style={lineAboveStyle}></div>
            <nav className="nav">
              <ul className="nav-list">
                <li className="nav-item"><Link to="/">HOME</Link></li>

                <li className="nav-item">
                  <a href="/bookmarks" onClick={(e) => handleProtectedClick(e, '/bookmarks')}>
                    <img src="/images/bookmarks.png" alt="Bookmarks" className="nav-icon bookmark-icon" />
                  </a>
                </li>

                {/* أيقونة النشر في المنتصف */}
                <li className="nav-item write-center">
                  <a href="/write" onClick={(e) => handleProtectedClick(e, '/write')}>
                    <img src="/images/write.png" alt="Write" className="nav-icon write-icon" />
                  </a>
                </li>

                <li className="nav-item">
                  <a href="/ad" onClick={(e) => handleProtectedClick(e, '/ad')}>ADs</a>
                </li>

                <li className="nav-item">
                  {user ? (
                    <a href="/profile" onClick={(e) => handleProtectedClick(e, '/profile')}>MY COLUMN</a>
                  ) : (
                    <button
                      className="auth-button"
                      onClick={openAuthModal}
                      style={{
                        background: 'none',
                        border: 'none',
                        color: '#333',
                        fontWeight: 'bold',
                        cursor: 'pointer'
                      }}
                    >
                      LOGIN
                    </button>
                  )}
                </li>
              </ul>
            </nav>
            <div className="line-image-below-nav" style={lineBelowStyle}></div>
          </div>
        </div>
      </div>

      <div style={{ height: '185px' }}></div>
    </>
  );
};

export default Header;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomEditor from '../components/CustomEditor';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { saveArticleWithLocation as saveArticle } from '../services/firebase/geolocation';
import '../styles/global.css';

const WritePage = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const navigate = useNavigate();

  const bgStyle = {
    backgroundColor: '#f9f7f2',
    minHeight: '100vh'
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
        setUserInfo({
          name: user.displayName || 'Anonymous',
          username: user.email?.split('@')[0],
          column: 'My Column',
          columnId: 'my-column'
        });
      } else {
        setIsLoggedIn(false);
        setUserInfo(null);
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  const handleSaveArticle = async (articleData) => {
    if (!isLoggedIn || !userInfo) {
      setShowAuthModal(true);
      return;
    }

    try {
      setSaving(true);
      setError(null);

      console.log("Saving article with data:", articleData);

      const saved = await saveArticle({
        ...articleData,
        ...userInfo
      });

      console.log("Article saved successfully:", saved);
      
      if (saved && saved.id) {
        navigate(`/article/${saved.id}`);
      } else {
        throw new Error('Failed to get article ID after saving');
      }
    } catch (err) {
      console.error('Error saving article:', err);
      setError(err.message || 'Failed to save article. Please try again.');
    } finally {
      setSaving(false);
    }
  };

  const toggleAuthModal = () => setShowAuthModal(!showAuthModal);

  if (loading) return <div className="container">Loading...</div>;

  return (
    <div style={bgStyle}>
      <div className="container">
        <h1 style={{ textAlign: 'center', marginBottom: '2rem' }}>Write Your Article</h1>

        {!isLoggedIn && (
          <div style={{ padding: '1rem', backgroundColor: '#f8f8f8', border: '1px solid #ddd', marginBottom: '2rem', textAlign: 'center' }}>
            <p>You need to be logged in to publish articles.</p>
            <button
              onClick={toggleAuthModal}
              style={{ padding: '0.5rem 1rem', backgroundColor: '#343434', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer', marginTop: '0.5rem' }}
            >
              Login or Register
            </button>
          </div>
        )}

        {error && <div style={{ color: 'red', marginBottom: '1rem' }}>{error}</div>}

        {userInfo && <CustomEditor onSave={handleSaveArticle} user={userInfo} />}
      </div>

      {showAuthModal && (
        <div className="auth-modal" onClick={toggleAuthModal}>
          <div className="auth-modal-content" onClick={e => e.stopPropagation()}>
            <p>Please login or register to publish articles.</p>
            <button
              onClick={() => {
                toggleAuthModal();
                navigate('/');
              }}
              style={{ padding: '0.5rem 1rem', backgroundColor: '#343434', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer', marginTop: '1rem' }}
            >
              Go to Home Page to Login
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default WritePage;
